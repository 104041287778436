import { create } from "zustand";

export interface PaymentState {
  paymentIntentId: string;
  clientSecret: string;
  productId: number | string;

  isCheckout: boolean;
  isOrdered: boolean;

  setPaymentIntentId: (value: string) => void;
  setClientSecret: (value: string) => void;
  setProductId: (value: number | string) => void;
  setIsCheckout: (value: boolean) => void;
  setIsOrdered: (value: boolean) => void;
  clear: () => void;
}

export const usePaymentStore = create<PaymentState>(set => ({
  paymentIntentId: "",
  clientSecret: "",
  productId: "",
  isCheckout: false,
  isOrdered: false,
  setPaymentIntentId: (value: string) => {
    set({
      paymentIntentId: value,
    });
  },
  setClientSecret: (value: string) => {
    set({
      clientSecret: value,
    });
  },
  setProductId: (value: number | string) => {
    set({
      productId: value,
    });
  },
  setIsCheckout: (value: boolean) => {
    set({
      isCheckout: value,
    });
  },
  setIsOrdered: (value: boolean) => {
    set({
      isOrdered: value,
    });
  },
  clear: () => {
    set({
      paymentIntentId: "",
      clientSecret: "",
      productId: "",
      isCheckout: false,
      isOrdered: false,
    });
  },
}));

export const usePaymentState = () => {
  return usePaymentStore(state => {
    return state;
  });
};
