const enum CookieType {
  LANG = "lang",
  TOKEN = "lpbt",
  ACCOUNT_NUMBER = "lpbt_acc",
  HASHED_PASSWORD = "lpbt_hs",
  CURRENCY = "currency",
  AFFILIATE = "affiliate",
}

export default CookieType;
