/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import "./src/styles/global.css";
import "./src/styles/layout.css";
import "./src/styles/flicking-pagination.css";

import { type GatsbyBrowser } from "gatsby";

import wrapPageElement from "./src/wrappers/wrap_page_element";

export { wrapPageElement };

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};
