import "react-toastify/dist/ReactToastify.css";

import React, { type FC, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useI18next } from "gatsby-plugin-react-i18next";

import Header from "@components/header";
import LiveChatPopup from "@components/live_chat_popup";
import CookieType from "@constants/CookieType";
import { useAccountDetailState } from "@stores/account_detail";
import { useAuthenticationState } from "@stores/authentication";
import { useRegionalSettingState } from "@stores/regional_setting";
import Cookies from "js-cookie";

const Layout: FC<{ children?: any }> = ({ children }) => {
  const { path, language, changeLanguage } = useI18next();

  const authenticationState = useAuthenticationState();
  const accountDetailState = useAccountDetailState();
  const regionalSettingState = useRegionalSettingState();

  const authPaths = ["login", "register", "forgot-password"];
  const isAuthPage = authPaths.some(authPath => path.includes(authPath));

  useEffect(() => {
    const tokenFromCookie = Cookies.get(CookieType.TOKEN) ?? "";
    if (authenticationState.getToken() !== tokenFromCookie) {
      if (tokenFromCookie) {
        authenticationState.setToken(tokenFromCookie);
        accountDetailState.load(tokenFromCookie);
      } else {
        authenticationState.clear();
        accountDetailState.clear();
      }
      if (process.env.GATSBY_DEBUG) {
        console.log("loaded token from cookie 🍪.");
      }
    }

    const langFromCookie = Cookies.get(CookieType.LANG) ?? "";
    if (language !== langFromCookie) {
      changeLanguage(langFromCookie || "en");
      if (process.env.GATSBY_DEBUG) {
        console.log("loaded language token from cookie 🍪.");
      }
    }

    const currencyFromCookie = Cookies.get(CookieType.CURRENCY) ?? "";
    if (regionalSettingState.currency !== currencyFromCookie) {
      regionalSettingState.updateCurrency(
        currencyFromCookie ?? process.env.GATSBY_DEFAULT_CURRENCY,
      );
      if (process.env.GATSBY_DEBUG) {
        console.log("loaded currency token from cookie 🍪.");
      }
    }

    if (typeof window !== "undefined" && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      regionalSettingState.updateAffiliate(params.get("a") ?? "site");
      if (process.env.GATSBY_DEBUG) {
        console.log("registered affiliate to cookie 🍪.");
      }
    }
  }, []);

  return (
    <div className="contents selection:bg-primary/80 selection:text-white">
      {!isAuthPage && <Header />}
      <div className={!isAuthPage ? "mt-28" : "!py-0"}>
        <main>{children}</main>
      </div>
      <ToastContainer />
      <LiveChatPopup />
    </div>
  );
};

export default Layout;
