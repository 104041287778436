import React, { type FC } from "react";

const LangIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00472 4.94287H4.77308L4.34023 7.1077H5.43758L5.00472 4.94287Z"
      className="fill-primary"
    />
    <path
      d="M13.7602 9.27246C13.9939 9.90023 14.3047 10.4084 14.6487 10.8408C14.9927 10.4084 15.3396 9.90019 15.5733 9.27246H13.7602Z"
      className="fill-primary"
    />
    <path
      d="M16.8496 3.2832H10.1284L11.5298 14.5287C11.5546 14.9887 11.4289 15.4217 11.1287 15.7607L8.75539 18.4731H16.8496C17.7449 18.4731 18.4732 17.7448 18.4732 16.8495V4.94291C18.4732 4.0476 17.7449 3.2832 16.8496 3.2832ZM16.8496 9.27257H16.705C16.3971 10.2606 15.9087 11.0337 15.4012 11.6431C15.7988 12.0066 16.2239 12.3048 16.6466 12.6387C16.8797 12.8253 16.9178 13.1657 16.7307 13.3993C16.5444 13.6325 16.2027 13.6703 15.9701 13.4833C15.5108 13.1208 15.0798 12.8174 14.6487 12.4218C14.2176 12.8174 13.8226 13.1208 13.3633 13.4833C13.1307 13.6703 12.789 13.6325 12.6028 13.3993C12.4156 13.1657 12.4537 12.8253 12.6868 12.6387C13.1095 12.3048 13.4985 12.0066 13.8961 11.6431C13.3886 11.0337 12.9363 10.2606 12.6284 9.27257H12.4838C12.1847 9.27257 11.9426 9.0305 11.9426 8.73136C11.9426 8.43222 12.1847 8.19015 12.4838 8.19015H14.1075V7.64894C14.1075 7.3498 14.3495 7.10774 14.6487 7.10774C14.9478 7.10774 15.1899 7.3498 15.1899 7.64894V8.19015H16.8496C17.1487 8.19015 17.3908 8.43222 17.3908 8.73136C17.3908 9.0305 17.1487 9.27257 16.8496 9.27257Z"
      className="fill-primary"
    />
    <path
      d="M8.80956 1.42226C8.70861 0.611492 8.01572 0 7.19864 0H1.62362C0.728321 0 0 0.728321 0 1.62362V13.6023C0 14.4976 0.728321 15.226 1.62362 15.226C4.85896 15.226 7.18641 15.226 10.1585 15.226C10.3164 15.0455 10.4487 14.9325 10.4543 14.6969C10.4557 14.6379 8.81688 1.48085 8.80956 1.42226ZM6.63679 10.3445C6.34981 10.4036 6.05911 10.2173 5.99993 9.92012L5.65403 8.19027H4.12382L3.77792 9.92012C3.71979 10.2129 3.43753 10.4053 3.14106 10.3445C2.84827 10.2859 2.65798 10.001 2.71665 9.70767L3.79902 4.2956C3.84975 4.04296 4.07176 3.86061 4.32966 3.86061H5.44816C5.70606 3.86061 5.92806 4.04296 5.97879 4.2956L7.06121 9.70767C7.11987 10.001 6.92962 10.2859 6.63679 10.3445Z"
      className="fill-primary"
    />
    <path
      d="M6.3235 16.3086L6.41637 17.0512C6.47829 17.5485 6.80871 18.0556 7.35223 18.3088C8.37793 17.1797 7.75738 17.8628 9.16921 16.3086H6.3235Z"
      className="fill-primary"
    />
  </svg>
);

export default LangIcon;
