import CookieType from "@constants/CookieType";
import Cookies from "js-cookie";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface RegionalSettingState {
  currency: string;
  affiliate: string;
  updateCurrency: (value: string) => void;
  updateAffiliate: (value: string) => void;
  reset: () => void;
}

export const useRegionalSettingStore = create<RegionalSettingState>()(
  persist(
    set => ({
      currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
      affiliate: "site",
      updateCurrency: (value: string) => {
        set({
          currency: value,
        });
        Cookies.set(CookieType.CURRENCY, value, {
          domain: process.env.GATSBY_ROOT_DOMAIN,
        });
      },
      updateAffiliate: (value: string) => {
        set({
          affiliate: value,
        });
        Cookies.set(CookieType.AFFILIATE, value, {
          domain: process.env.GATSBY_ROOT_DOMAIN,
        });
      },
      reset: () => {
        set({
          currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
        });
      },
    }),
    {
      name: "regional-setting-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useRegionalSettingState = () => {
  return useRegionalSettingStore(state => {
    return state;
  });
};
