import CountryCurrency from "@data/country_currency.json";
import type Coupon from "@interfaces/Coupon";
import { type CreditCard, type Wallet } from "@interfaces/Payment";
import axios from "@utilities/axios";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type CountryCurrencyType = Record<string, string>;

export interface AccountDetailState {
  license: {
    cli: string;
    name: string;
    phone: string;
    email: string;
  };
  profile: {
    image: string;
  };
  referralCode: string;
  lastCountry: {
    iso2: string;
    name: string;
  };
  paymentMethods: {
    cards: CreditCard[];
    wallets: Wallet[];
    coupons: Coupon[];
  };
  load: (
    licenseToken: string,
    updateCurrency?: (currency: string) => void,
  ) => Promise<void>;
  updatePaymentMethods: (
    cards: CreditCard[],
    wallets: Wallet[],
    coupons: Coupon[],
  ) => void;
  clear: () => void;
}

export const useAccountDetailStore = create<AccountDetailState>()(
  persist(
    set => ({
      license: {
        cli: "",
        name: "",
        phone: "",
        email: "",
      },
      profile: {
        image: "",
      },
      referralCode: "",
      lastCountry: {
        iso2: "",
        name: "",
      },
      paymentMethods: {
        cards: [],
        wallets: [],
        coupons: [],
      },
      load: async (
        licenseToken,
        updateCurrency?: (currency: string) => void,
      ) => {
        let mapUrl = "/maps_autocomplete/";
        mapUrl += "?action=get_current_location";
        mapUrl += "&license_token=" + licenseToken;
        axios
          .get(mapUrl)
          .then(response => {
            if (process.env.GATSBY_DEBUG) {
              console.log("Map Autocomplete JSON response:", response.data);
            }
            const lastCountryIso2 = response.data.last_country_iso2;
            set({
              lastCountry: {
                iso2: lastCountryIso2,
                name: response.data.last_country_name,
              },
            });
            let currency = CountryCurrency.find(
              (countryCurrency: CountryCurrencyType) =>
                lastCountryIso2 in countryCurrency,
            );
            currency = currency
              ? currency[lastCountryIso2]
              : process.env.GATSBY_DEFAULT_CURRENCY;
            if (updateCurrency) {
              updateCurrency(currency);
            }

            let paymentMethodUrl = "/payment_methods/";
            paymentMethodUrl += "?license_token=" + licenseToken;
            paymentMethodUrl += "&currency=" + currency;
            axios
              .get(paymentMethodUrl)
              .then(response => {
                if (process.env.GATSBY_DEBUG) {
                  console.log("Payment Method JSON response:", response.data);
                }

                set({
                  paymentMethods: {
                    cards: response.data.cards,
                    wallets: response.data.wallets,
                    coupons: response.data.coupons,
                  },
                });
              })
              .catch(error => {
                if (error.response) {
                  console.error(`Response status: ${error.response.status}`);
                } else {
                  console.error(error.message);
                }
              });
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
          });

        let accountDetailUrl = "/my_account_details/";
        accountDetailUrl += "?license_token=" + licenseToken;
        axios.get(accountDetailUrl).then(response => {
          if (process.env.GATSBY_DEBUG) {
            console.log("Account Detail JSON response:", response.data);
          }
          set({
            license: {
              cli: response.data.license_cli,
              name: response.data.license_name,
              phone: response.data.license_phone,
              email: response.data.license_email,
            },
          });
        });

        let profileImageUrl = "/media/";
        profileImageUrl += "?license_token=" + licenseToken;
        axios.get(profileImageUrl).then(response => {
          set({
            profile: {
              image: response.data.profile_image,
            },
          });
        });

        let referralUrl = "/referral/";
        referralUrl += "?license_token=" + licenseToken;
        axios.get(referralUrl).then(response => {
          set({
            referralCode: response.data.ref_code,
          });
        });
      },
      updatePaymentMethods: (
        cards: CreditCard[],
        wallets: Wallet[],
        coupons: Coupon[],
      ) => {
        set({
          paymentMethods: {
            cards,
            wallets,
            coupons,
          },
        });
      },
      clear: () => {
        set({
          license: {
            cli: "",
            name: "",
            phone: "",
            email: "",
          },
          profile: {
            image: "",
          },
          referralCode: "",
          lastCountry: {
            iso2: "",
            name: "",
          },
          paymentMethods: {
            cards: [],
            wallets: [],
            coupons: [],
          },
        });
      },
    }),
    {
      name: "account-detail-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useAccountDetailState = () => {
  return useAccountDetailStore(state => {
    return state;
  });
};
