import React, { type FC } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

import AlertModal from "@components/alert_modal";
import CookieType from "@constants/CookieType";
import { useAccountDetailState } from "@stores/account_detail";
import { useAuthenticationState } from "@stores/authentication";
import { usePaymentState } from "@stores/payment";
import { useRegionalSettingState } from "@stores/regional_setting";
import { defaultRipple } from "@utilities/helper";
import Cookies from "js-cookie";

const LogoutPopup: FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}> = ({ isModalOpen, setIsModalOpen }) => {
  const { t, navigate } = useI18next();

  const accountDetailState = useAccountDetailState();
  const authenticationState = useAuthenticationState();
  const paymentState = usePaymentState();
  const regionalSettingState = useRegionalSettingState();

  const submitLogout = () => {
    accountDetailState.clear();
    authenticationState.clear();
    paymentState.clear();
    regionalSettingState.reset();
    Cookies.remove(CookieType.CURRENCY, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });
    Cookies.remove(CookieType.TOKEN, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });
    Cookies.remove(CookieType.ACCOUNT_NUMBER, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });
    Cookies.remove(CookieType.HASHED_PASSWORD, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });
    Cookies.remove(CookieType.AFFILIATE, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });

    setIsModalOpen(false);
    navigate("/login");
  };

  return (
    <AlertModal
      type="warning"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div className="flex flex-col gap-4">
        <span className="w-full text-center">
          {t("you_want_to_log_out_dialog")}
        </span>
        <div className="flex flex-row gap-4">
          <button
            ref={defaultRipple}
            className="custom-btn-primary w-full"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            {t("cancel_camule_case")}
          </button>
          <button
            ref={defaultRipple}
            className="custom-btn-secondary w-full"
            onClick={submitLogout}
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </AlertModal>
  );
};

export default LogoutPopup;
