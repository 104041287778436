import { navigate } from "gatsby";

import { default as defaultAxios } from "axios";

const axios = defaultAxios.create({
  baseURL: `${
    process.env.GATSBY_API_BASE_URL?.endsWith("/")
      ? process.env.GATSBY_API_BASE_URL
      : process.env.GATSBY_API_BASE_URL + "/"
  }${process.env.GATSBY_API_VERSION ?? ""}`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 903) {
      const errorMessage = error.response.data.message.toLowerCase();
      if (errorMessage?.includes("token") && errorMessage.includes("expired")) {
        navigate("/login?expired=1");
      }
    }
    return await Promise.reject(error);
  },
);

export default axios;
