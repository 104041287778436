import CookieType from "@constants/CookieType";
import SocialProvider from "@constants/SocialProvider";
import axios from "@utilities/axios";
import Cookies from "js-cookie";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface AuthenticationState {
  token: string;
  account_number: string;
  hashed_password: string;
  register: (
    fullName: string,
    email: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
    alreadyHaveAccountCallback?: (socialAccounts: SocialProvider[]) => void,
  ) => Promise<void>;
  login: (
    phoneNumber: number | string,
    password: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  reloadLogin: (
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  loginWithLicenseToken: (
    licenseToken: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  getToken: () => string;
  setToken: (value: string) => void;
  isAuthenticated: () => boolean;
  clear: () => void;
}

export const useAuthenticationStore = create<AuthenticationState>()(
  persist(
    (set, get) => ({
      token: "",
      account_number: "",
      hashed_password: "",
      register: async (
        fullName: string,
        email: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
        alreadyHaveAccountCallback?: (socialAccounts: SocialProvider[]) => void,
      ) => {
        let voucherCode = "";
        if (typeof window !== "undefined" && window.location.search) {
          const params = new URLSearchParams(window.location.search);
          voucherCode = params.get("a") ?? "";
        }
        if (!voucherCode) {
          voucherCode = "SITE";
        }
        let searchLicenseRequest =
          "/sim_card_activation/?action=search-licenses";
        searchLicenseRequest += "&contact_email=" + email;
        axios
          .get(searchLicenseRequest)
          .then(response => {
            if (
              response.data.social_to_propose &&
              Object.values(SocialProvider).includes(
                response.data.social_to_propose as SocialProvider,
              )
            ) {
              if (alreadyHaveAccountCallback) {
                if (callback) {
                  callback();
                }
                alreadyHaveAccountCallback([response.data.social_to_propose]);
              }
            } else {
              activate();
            }
          })
          .catch(error => {
            if (errorCallback) {
              errorCallback(error);
            }
          })
          .finally(() => {});

        const activate = () => {
          let activateRequest = "/sim_card_activation/?is_app_registration=1";
          activateRequest += "&from=esim";
          activateRequest += "&fullname=" + fullName;
          activateRequest += "&contact_email=" + email;
          activateRequest += "&voucher-code=" + voucherCode;

          axios
            .get(activateRequest)
            .then(response => {
              let checkRequest = "/sim_card_activation_status/";
              checkRequest += "?is_app_registration=1";
              checkRequest += "&voucher-code=" + voucherCode;
              checkRequest += "&transaction-id=" + response.data.message;
              checkRequest += "&contact_email=" + email;
              startCheckingAccountInterval(checkRequest);
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback(error);
              }
            })
            .finally(() => {});
        };

        let interval: NodeJS.Timeout;
        let isRegisterSuccessful = false;
        const startCheckingAccountInterval = (request: string) => {
          interval = setInterval(() => {
            checkActivationStatus(request);
          }, 5000);
          setTimeout(() => {
            if (!isRegisterSuccessful) {
              if (process.env.GATSBY_DEBUG) {
                console.log("signing up timeout");
              }
              clearInterval(interval);
              if (errorCallback) {
                errorCallback({
                  message:
                    "An expected error occurred during account creation. Please try again or contact support.",
                });
              }
              if (callback) {
                callback();
              }
            }
          }, 30000);
        };

        const checkActivationStatus = (request: string) => {
          axios
            .get(request)
            .then(response => {
              if (process.env.GATSBY_DEBUG) {
                console.log("Request success : ", response.data);

                console.log("Request success result: " + response.data.message);
              }

              if (response.data.message === "2") {
                clearInterval(interval);
                isRegisterSuccessful = true;

                const licenseToken =
                  response.data.username + ":" + response.data.hashed_password;

                get().loginWithLicenseToken(
                  licenseToken,
                  () => {
                    if (callback) {
                      callback();
                    }
                  },
                  response => {
                    if (successCallback) {
                      successCallback(response);
                    }
                  },
                  error => {
                    if (errorCallback) {
                      errorCallback(error);
                    }
                  },
                );
              }
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback(error);
              }
            });
        };
      },
      login: async (
        phoneNumber: number | string,
        password: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        let isCallbackAlreadyCalled = false;
        let isErrorCallbackAlreadyCalled = false;
        const autoProvisionRequest =
          "/autoprovision/?format=json&cloud_username=" +
          phoneNumber +
          "&cloud_password=" +
          password;

        axios
          .get(autoProvisionRequest)
          .then(response => {
            if (process.env.GATSBY_DEBUG) {
              console.log("JSON response:", response.data);
            }
            const licenseToken =
              response.data.cloud_username +
              ":" +
              response.data.hashed_password;
            get().loginWithLicenseToken(
              licenseToken,
              () => {
                if (callback && !isCallbackAlreadyCalled) {
                  callback();
                  isCallbackAlreadyCalled = true;
                }
              },
              response => {
                if (successCallback) {
                  successCallback(response);
                }
              },
              error => {
                if (errorCallback && !isErrorCallbackAlreadyCalled) {
                  errorCallback(error);
                  isErrorCallbackAlreadyCalled = true;
                }
              },
            );
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
            if (errorCallback && !isErrorCallbackAlreadyCalled) {
              errorCallback(error);
              isErrorCallbackAlreadyCalled = true;
            }
          })
          .finally(() => {
            if (callback && !isCallbackAlreadyCalled) {
              callback();
              isCallbackAlreadyCalled = true;
            }
          });
      },
      reloadLogin: async (
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        const account_number_cookie = Cookies.get(CookieType.ACCOUNT_NUMBER);
        const account_number_stored = get().account_number;
        const hashed_password_cookie = Cookies.get(CookieType.HASHED_PASSWORD);
        const hashed_password_stored = get().hashed_password;

        let account_number = "";
        let hashed_password = "";
        if (
          account_number_cookie &&
          account_number_cookie === account_number_stored
        ) {
          account_number = account_number_cookie;
        }
        if (
          hashed_password_cookie &&
          hashed_password_cookie === hashed_password_stored
        ) {
          hashed_password = hashed_password_cookie;
        }

        if (!account_number || !hashed_password) {
          if (errorCallback) {
            errorCallback(null);
            return;
          }
        }
        const licenseToken = account_number + ":" + hashed_password;
        get().loginWithLicenseToken(
          licenseToken,
          callback,
          successCallback,
          errorCallback,
        );
      },
      loginWithLicenseToken: async (
        licenseToken: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        const loginRequest = "/login?license_token=" + licenseToken;
        if (process.env.GATSBY_DEBUG) {
          console.log("loginRequest:", loginRequest);
        }
        axios
          .get(loginRequest)
          .then(response => {
            const loginCredentials = licenseToken.split(":");
            const account_number = loginCredentials[0];
            const hashed_password = loginCredentials[1];
            set({
              token: response.data.token,
              account_number,
              hashed_password,
            });
            Cookies.set(CookieType.TOKEN, response.data.token, {
              domain: process.env.GATSBY_ROOT_DOMAIN,
            });
            Cookies.set(CookieType.ACCOUNT_NUMBER, account_number, {
              domain: process.env.GATSBY_ROOT_DOMAIN,
            });
            Cookies.set(CookieType.HASHED_PASSWORD, hashed_password, {
              domain: process.env.GATSBY_ROOT_DOMAIN,
            });
            if (successCallback) {
              successCallback(response);
            }
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
            if (errorCallback) {
              errorCallback(error);
            }
          })
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      },
      getToken: () => {
        return get().token;
      },
      setToken: (value: string) => {
        set({
          token: value,
        });
      },
      isAuthenticated: () => {
        return !!get().token;
      },
      clear: () => {
        set({
          token: "",
          account_number: "",
          hashed_password: "",
        });
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useAuthenticationState = () => {
  return useAuthenticationStore(state => {
    return state;
  });
};
