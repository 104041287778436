import { useI18next } from "gatsby-plugin-react-i18next";

import { useRipple } from "@chaocore/ripple";
import type Product from "@interfaces/Product";
import tailwindConfig from "@root/tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";

export const defaultRipple = useRipple({});

export const primaryRipple = useRipple({
  color: resolveConfig(tailwindConfig).theme.colors.primary.DEFAULT,
});

export const getDurationText = (product: Product) => {
  const { t } = useI18next();
  let durationText = "";
  switch (product.duration) {
    case -1:
      durationText = t("no_expiration");
      break;
    case 7:
    case 30:
      if (product.has_subscription) {
        durationText = t("monthly");
      } else {
        durationText = product.duration + " " + t("days");
      }
      break;
  }
  return durationText;
};

export const handleRedirectToApp = () => {
  if (typeof window !== "undefined" && window.location.href) {
    const appUrl = process.env.GATSBY_APP_URL ?? "";

    let fallbackUrl;
    if (/android/i.test(navigator.userAgent)) {
      fallbackUrl = process.env.GATSBY_PLAY_STORE_LINK ?? "/";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      fallbackUrl = process.env.GATSBY_APP_STORE_LINK ?? "/";
    } else {
      fallbackUrl = process.env.GATSBY_SITE_URL ?? "/";
    }

    window.location.href = appUrl;

    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 3000);
  }
};
