import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";

export default {
  content: ["./src/**/*.{js,jsx,ts,tsx,svg}"],
  theme: {
    screens: {
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      xxl: "1400px",
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
      },
      screens: {
        sm: "576px",
        md: "768px",
        lg: "992px",
        xl: "1200px",
        xxl: "1400px",
      },
    },
    extend: {
      colors: {
        primary: {
          DEFAULT: "#F97C50",
          50: "#FFFFFF",
          100: "#FEF2EE",
          200: "#FDD5C6",
          300: "#FCB79F",
          400: "#FA9A77",
          500: "#F97C50",
          600: "#F7531A",
          700: "#D23C07",
          800: "#9B2C05",
          900: "#651D03",
          950: "#4A1503",
        },
        secondary: {
          DEFAULT: "#82BFB7",
          50: "#FBFDFD",
          100: "#EEF6F5",
          200: "#D3E8E6",
          300: "#B8DBD6",
          400: "#9DCDC7",
          500: "#82BFB7",
          600: "#5DACA2",
          700: "#478A81",
          800: "#34655F",
          900: "#21403C",
          950: "#172D2A",
        },
      },
      fontFamily: {
        sans: ["Poppins", ...defaultTheme.fontFamily.sans],
      },
    },
  },
  plugins: [],
} satisfies Config;
