import React, { type FC, useEffect } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";

import tailwindConfig from "@root/tailwind.config";
import { defaultRipple } from "@utilities/helper";
import { AnimatePresence, motion } from "framer-motion";
import resolveConfig from "tailwindcss/resolveConfig";

const Modal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  bgColor?: string;
  title?: string;
  isCloseButtonExist?: boolean;
  closeOnBackgroundClick?: boolean;
  isContentScrollable?: boolean;
  classNames?: string;
  children?: any;
}> = ({
  isModalOpen,
  setIsModalOpen,
  bgColor = "#fff",
  title = "",
  isCloseButtonExist = true,
  closeOnBackgroundClick = true,
  isContentScrollable = false,
  classNames = "",
  children,
}) => {
  const isSSR =
    typeof document === "undefined" && typeof window === "undefined";
  let backgroundColor: string;
  let closeBtnColor: string;
  switch (bgColor) {
    case "primary":
      backgroundColor =
        resolveConfig(tailwindConfig).theme.colors.primary.DEFAULT;
      closeBtnColor = resolveConfig(tailwindConfig).theme.colors.white;
      break;
    case "secondary":
      backgroundColor =
        resolveConfig(tailwindConfig).theme.colors.secondary.DEFAULT;
      closeBtnColor = resolveConfig(tailwindConfig).theme.colors.white;
      break;
    default:
      backgroundColor = bgColor;
      closeBtnColor = resolveConfig(tailwindConfig).theme.colors.gray[400];
      break;
  }
  useEffect(() => {
    if (!isSSR) {
      const toggleOverflow = (value: boolean) => {
        const action = value ? "add" : "remove";
        document.body.classList[action]("overflow-hidden");
        document.documentElement.classList[action]("overflow-hidden");
      };

      isModalOpen ? toggleOverflow(true) : toggleOverflow(false);

      return () => {
        toggleOverflow(false);
      };
    }
  }, [isModalOpen]);

  return (
    !isSSR &&
    ReactDOM.createPortal(
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed z-50 selection:bg-primary/80 selection:text-white"
            aria-labelledby={title ?? ""}
            role="dialog"
            aria-modal="true"
          >
            <div
              className="fixed inset-0 bg-gray-500/75 transition-opacity"
              aria-hidden="true"
              onClick={() => {
                if (closeOnBackgroundClick) {
                  setIsModalOpen(false);
                }
              }}
            ></div>

            <div className="pointer-events-none fixed inset-0 z-50 h-dvh w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                <motion.div
                  initial={{ opacity: 0, y: "10%", scale: 0.9 }}
                  animate={{
                    opacity: 1,
                    y: "0%",
                    scale: 1,
                  }}
                  exit={{
                    opacity: 0,
                    y: "10%",
                    scale: 0.9,
                  }}
                  className={
                    "pointer-events-auto relative flex w-full flex-col items-center justify-center gap-2 rounded-2xl p-4 text-left shadow-xl sm:my-8 sm:max-w-lg " +
                    classNames
                  }
                  transition={{ duration: 0.3 }}
                  style={{ backgroundColor }}
                >
                  {(isCloseButtonExist || title) && (
                    <div className="flex w-full flex-row items-center justify-between">
                      <div></div>
                      <div className="ml-10 text-xl font-bold">{title}</div>
                      <div>
                        {isCloseButtonExist && (
                          <button
                            ref={defaultRipple}
                            className={
                              (closeBtnColor ===
                              resolveConfig(tailwindConfig).theme.colors
                                .gray[400]
                                ? "focus:custom-secondary-focus-ring"
                                : "focus:ring-2 focus:ring-white/80 focus:ring-offset-1") +
                              " size-10 rounded-full border border-transparent p-0 outline-none transition duration-300 hover:rotate-90 hover:opacity-90"
                            }
                            onClick={() => {
                              setIsModalOpen(false);
                            }}
                          >
                            <IoClose
                              className="size-full"
                              style={{
                                color: closeBtnColor,
                              }}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      (isContentScrollable
                        ? "-mx-4 -mb-4 max-h-[75dvh] w-[calc(100%+2rem)] overflow-y-auto px-4 pb-4"
                        : "w-full") + " relative block"
                    }
                  >
                    {children}
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body,
    )
  );
};

export default Modal;
