import React, { type FC } from "react";

const Loader: FC<{ size?: number; className?: string }> = ({
  size,
  className,
}) => {
  const rootSize = size ?? 80;

  interface LoaderStyleType {
    size: number;
    border: number;
  }

  const outer: LoaderStyleType = {
    size: rootSize,
    border: rootSize * (5 / 80),
  };
  const middle: LoaderStyleType = {
    size: rootSize * 0.75,
    border: rootSize * (7 / 80),
  };
  const inner: LoaderStyleType = {
    size: rootSize * 0.5,
    border: rootSize * (5 / 80),
  };

  const getRootStyle = () => {
    return {
      width: `${rootSize}px`,
      height: `${rootSize}px`,
    };
  };

  const calculateInnerStyle = (style: LoaderStyleType) => {
    return {
      width: `${style.size}px`,
      height: `${style.size}px`,
      top: `calc(50% - ${style.size / 2}px)`,
      left: `calc(50% - ${style.size / 2}px)`,
      borderWidth: `${style.border}px`,
    };
  };

  return (
    <div
      style={getRootStyle()}
      className={"relative transition duration-300 " + (className ?? "")}
    >
      <div
        style={calculateInnerStyle(outer)}
        className="absolute origin-center animate-spin rounded-full border-solid border-[#DF7158] border-l-transparent"
      ></div>
      <div
        style={calculateInnerStyle(middle)}
        className="absolute origin-center animate-[spin_3s_linear_infinite] rounded-full border-solid border-[#EFB950] border-l-transparent"
      ></div>
      <div
        style={calculateInnerStyle(inner)}
        className="absolute origin-center animate-spin rounded-full border-solid border-[#6ABEE9] border-t-transparent [animation-direction:reverse]"
      ></div>
    </div>
  );
};

export default Loader;
