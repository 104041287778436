import React, { type FC, type ReactElement } from "react";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
} from "react-icons/fa";

import Modal from "@components/modal";

const enum AlertType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

const AlertModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  type?: string;
  children?: any;
}> = ({ isModalOpen, setIsModalOpen, type = AlertType.INFO, children }) => {
  const getIcon = (): ReactElement => {
    switch (type) {
      case AlertType.INFO:
      default:
        return <FaInfoCircle className="size-full text-primary" />;
        break;
      case AlertType.WARNING:
        return <FaExclamationCircle className="size-full text-primary" />;
        break;
      case AlertType.ERROR:
        return <FaExclamationCircle className="size-full text-red-500" />;
        break;
      case AlertType.SUCCESS:
        return <FaCheckCircle className="size-full text-secondary" />;
        break;
    }
  };
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isCloseButtonExist={false}
    >
      <span className="absolute left-1/2 z-30 size-20 -translate-x-1/2 translate-y-[-80%] rounded-full border-solid border-white bg-white p-2">
        {getIcon()}
      </span>
      <div className="mt-4">{children}</div>
    </Modal>
  );
};

export default AlertModal;
