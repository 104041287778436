import React from "react";
import { type WrapPageElementNodeArgs } from "gatsby";

import Layout from "@components/layout";

const wrapPageElement = ({ element }: WrapPageElementNodeArgs) => {
  const newElement = React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children.props,
      React.createElement(
        Layout,
        undefined,
        element.props.children.props.children,
      ),
    ),
  );

  return newElement;
};

export default wrapPageElement;
